<template>
    <div>
        <div style='display:flex;flex-direction:row;justify-content:center'>
            <p>To add a show, start typing its title and select from the autocomplete (be patient)!</p>
            <b-button id="malInfo" v-b-popover.hover.top:="signedin?'Type at least four letters for faster queries. Try both Eng/Jap versions of the title (e.g. Shingeki...)!':'Results are only returned for the top 200 animes. Sign up to access 10,000 animes!'" size="lg" variant="primary" class="mb-2">
                <b-icon icon="question-circle-fill" aria-label="Help"></b-icon>
            </b-button>

        </div>
        <div style='display:flex;flex-direction:row;justify-content:center'>
            
            <div v-if="signedin" style='margin-right:10px:margin-top:-10px;'>Import xml from MyAnimeList:  </div>
            <!-- <input v-model="mal" v-on:keyup.enter="malCall" placeholder="Your MAL username"> -->
            
            <input v-if="signedin" id="fileInput" type="file" @change="handleUpload" accept=".xml"/>
            <b-button  v-if="signedin" id="malInfo" v-b-popover.hover.top:="'Export your list at myanimelist.net/panel.php?go=export then unzip to xml.'" size="lg" variant="primary" class="mb-2">
                <b-icon icon="question-circle-fill" aria-label="Help"></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>

import { ApiUtil } from "@/utils/api-utils.js";

export default {
props: {

    paid: Boolean,
    signedin: Boolean
  },
  data() {
    return {
      xml: null,
      hover:false
    };
  },
  components: {
  },
  methods: {
    loadXmlResult(xml) {
        console.log("got",xml["res"]);
        this.$emit("xmlRes",xml["res"]);
    },
    async handleUpload() {
        var fileInput = document.getElementById('fileInput');
        var file = fileInput.files[0];
        var reader = new FileReader();
        let paid=this.paid;
        let loadXmlResult=this.loadXmlResult.bind(this);
        reader.addEventListener('load', async function(){
            let xml = reader.result;
            
            const res=await ApiUtil.processXML(xml,paid);
            console.log(res);
            loadXmlResult(res);
        });
        this.xml = await reader.readAsText(file);

    }
  }
};
</script>
<style>
#malInfo {
  background-color:transparent;
  display:flex;
  
  justify-content: center;
  
  border:none;
  width:0.5%;
  height:1%;
  margin-left:5px;
  margin-top:-10px;
}

#fileInput {
    margin-top:-5px;
    margin-left:5px;
}
#mdi {
    background-color: transparent;
    
    padding: 0px;
    border-radius: 50%;
}
</style>