import axios from 'axios';
import * as Config from './config.json';
axios.defaults.baseURL = Config.API_URL;

// const url="http://localhost:5000";
const url="https://bigotaku.net";



export class ApiUtil {
    static async retrieveRecs(shows, num, paid,maxLim=500) {
      const payload = { shows: shows, num_res: parseInt(num),max_lim:parseInt(maxLim) };
      payload.paid=paid;
      const prom = await axios.post(`${url}/predict`, payload );
      return prom.data;
    }
    static async getMalUser(mal,paid) {
      const payload={mal:mal,paid:paid};
      const prom = await axios.post(`${url}/mal`, payload );
      return prom.data;
    }
    static async processXML(xml,paid) {
      const payload={xml:xml,paid:paid};
      const prom=await axios.post(`${url}/xml`,payload);
      return prom.data;
    }
  }
  