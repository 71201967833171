<template>
  <div id="app">
    <Viz @mount10000="mount10000" @unmount10000="unmount10000"
    @loggedin="loggedIn" @loggedout="loggedOut" />
    <img alt="Vue logo" height="25%" width="25%" :src="gif" style="margin-top:20px;margin-bottom:20px">
    
    <Home :paid="paid" :signedin="loggedin" @recommended="recommended" @rezoom="reZoom" @zoom="handleZoom" 
    msg="Anime Recommendation Engine" v-bind:mode="method"/>
    <br><br>
    <p>How can I serve you, senpai?</p>
    <b-button-toolbar key-nav aria-label="Toolbar with button groups">
      <div style="margin:auto">
      <b-button-group class="mx-1">
        <b-button id="wmf" v-bind:style="'background-color:'+(method==0?colors[0]:colors[1])" v-b-popover.hover.top="'Weighted matrix factorization (WMF): computes your embedding based on shows you enjoyed, finds other shows via nearest neighbors!'"
        @click="sorry">WMF</b-button>
        <b-button id="topK" v-bind:style="'background-color:'+(method==1?colors[0]:colors[1])" v-b-popover.hover.top="'Top-K user collaborative filtering algorithm: finds K most similar users based on watch history, recommends other shows they\'re highly fond of!'" 
        @click="sorry">Top-k CF</b-button>
        <b-button id="dan" v-bind:style="'background-color:'+(method==2?colors[0]:colors[1])" v-b-popover.hover.top="'DeepNaniNet: our proprietary technology using a dense neural network fine-tuned from state-of-the-art models and techniques in NLP and graph neural networks - searches for deeper, thematic connections between shows.'"  
        @click="sorry">DeepNaniNet</b-button>
      </b-button-group>
      </div>
    </b-button-toolbar>
    <br>
    
    
  </div>
</template>

<script>
import Home from './components/Home.vue';
import Viz from './components/Viz.vue';
import cytoscape from 'cytoscape';
import animeGraph from './assets/smallGraph/anime_graph.json';
import animeGraph10000 from './assets/largeGraph/10000_anime_graph.json';
import reprs from './assets/smallGraph/reprs.json';
import reprs10000 from './assets/largeGraph/10000_reprs.json';
import titles from './assets/10000_titles.json';

const gifs=['fma_2.gif', 'opm_1.gif', 'mob_1.gif', 'fma_1.gif', '.DS_Store', 'dno_1.gif', 'aot_2.gif', 'aot_3.gif', 'aot_1.gif', 'hai_1.gif', 'onp_1.gif', 'hxh_2.gif', 'hxh_3.gif', 'hxh_1.gif', 'ste_1.gif', 'dem_1.gif', 'nar_2.gif', 'eva_1.gif', 'gin_1.gif', 'cog_1.gif', 'beb_1.gif', 'nar_1.gif'];

const createGraph=function(animeGraph,reprs){
  var elems=[];
  for(let s in reprs){
    
    
    var x=5000*reprs[s][0];
    var y=5000*reprs[s][1];
    var node={data: {id:s,name:titles[s]},position:{x:x,y:y}};
    elems.push(node);
  }
  for(var show in animeGraph["recs"]){

    for(let i=0;i<animeGraph["recs"][show].length;i++){
      var show2=animeGraph["recs"][show][i];

      var w=Math.min(parseInt(animeGraph["weights"][show][show2]),100)*0.1;
      elems.push(({data:{id:`${show}_${show2}`,weight:w,source:show,target:show2}}))
    }
  }
  var style={
    'background-color': '#666',
    'label': 'data(name)',
  };
  let small=(reprs.length!=reprs10000.length);
  let minZoom=small?0.2:0.05;
  let maxZoom=small?2:5;
  if(small)style['font-size']='32px';
  return cytoscape({
        container: document.getElementById('viz'), // container to render in
        elements: elems,
        maxZoom: maxZoom,
        minZoom: minZoom,
        style: [ // the stylesheet for the graph
          {
            selector: 'node',
            style: style
          },

          {
            selector: 'edge',
            style: {
              'width': 'data(weight)',
              'line-color': '#ccc',
              'target-arrow-color': '#ccc',
              'target-arrow-shape': 'triangle',
              'curve-style': 'bezier',
              
            }
          }
        ],

        layout: {
          name: 'preset'
        }

        });

}
let cy=createGraph(animeGraph,reprs);


cy.mount(document.getElementById("viz"));
// cy.zoom({level:2,position:{x:Math.min(1000*reprs[5114][0],10),y:Math.min(1000*reprs[5114][1],600)}});
const recStyle={
  "background-color":'#0dfe05',
  "font-size":'256px',
  "font-weight":'bold',
  "z-index":'1000',
  "width":'250px',
  "height":'250px'
};
export default {
  name: 'App',
  components: {
    Home,
    Viz
  },
  data() {
    return {
      method: 0,
      colors: ['black','gray','gray'],
      chosen: [],
      recs:[],
      gif: "",

      paid: false,
      loggedin: false
    }
  },
  
  beforeMount() {
    var path=gifs[Math.floor(Math.random()*gifs.length)];
    this.gif=require("./assets/animeGifs/"+path);
    
  },
  created() {
    let defaultStyle = cy.getElementById("5114").style();
    this.defaultStyle = Object();
    for(let x in recStyle) this.defaultStyle[x] = defaultStyle[x];

  },
  methods: {
    idToElem : (r) => {
      return cy.getElementById(r.toString());
    },
    resetDefault(r) {
      this.idToElem(r).style(this.defaultStyle);
    },
    setRecStyle(r) {
      for(let k in recStyle) this.idToElem(r).style(k,recStyle[k]);

    },
    recommended(v) {
      cy.fit();
      for(let r of this.recs) this.resetDefault(r);
      this.recs = v;
      for(let r of v) this.setRecStyle(r)
    },
    loggedIn(){
      this.loggedin=true;
    },
    loggedOut(){
      this.loggedin=false;
    },
    mount10000() {
      alert("Please wait... 10,000 animes incoming.")
      this.paid=true;
      cy=createGraph(animeGraph10000,reprs10000);
      cy.mount(document.getElementById("viz"));
    },
    unmount10000() {
      this.paid=false;
      cy=createGraph(animeGraph,reprs);
      cy.mount(document.getElementById("viz"));
    },
    reZoom(indices) {

      cy.fit();
      for(let r of this.recs) this.resetDefault(r);
      for(let r of indices) this.resetDefault(r);
    },
    handleZoom(v) {
      cy.fit();
      var pos=cy.getElementById(v.toString()).position();
      cy.getElementById(v.toString()).style('background-color','blue');
      cy.zoom({level:2,position:pos});
    },
    sorry(event) {
      switch(event.target.id){
        case "wmf":
          this.method=0;
          break;
        case "topK":
          this.method=1;
          break;
        default:
          if(!this.loggedin)alert("Please sign up/in to use DeepNaniNet.");
          else this.method=2;
          
      }
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:aqua;
  margin-top: 0px;
}
</style>
