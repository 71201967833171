<template>
  <div
    v-if="true"
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      opacity: 1;
      padding-top: 10px;
    "
  >
    <button @click="submit">Donate</button>
    <b-modal v-model="curUser.expired" @hide="handleExpired">
      <div>
        You've used up your free trials of OtakuRoll! 
        <br>
        Please donate $1.99 to continue using the service.
        <br>
        <br>
        <button @click="submit">Donate</button>
      </div>
    </b-modal>
    
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      >
      </loading>
    </div>
    <div>
      <b-button v-b-modal.modal-1>
        <div @click="signout" v-if="curUser.user != null">Sign out</div>
        <div v-else>Sign up/in</div>
      </b-button>

      <b-modal
        v-if="curUser.user == null"
        id="modal-1"
        title="Member Sign in or Sign up"
      >
        <div>
          Being a member allows you to access DeepNaniNet, the full catalogue of 10,000 animes, and additional features like directly importing from MyAnimeList. If privacy is a concern, use a test email (e.g. email: deepnaninet@gmail.com, password: naninaninani), though use a real email to keep updated!
          <br>
          <br>
        </div>
        <b-form @submit="onAuthSubmit">
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
            description="Use this same email later for your donation to access Premium features."
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Password:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.password"
              type="password"
              placeholder="Enter password"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase";

var db = firebase.app().database();
var ref = db.ref();
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const stripePromise = loadStripe(
  "pk_live_51IYcPUKOVzXdHVhJrinBcXCu8b5cohZOIocJaRYb6DcwbcKG98CgYkJKvwIRR71OHnVKxvvWjow1Rd2WtD4DmpwH00y09EB8UM"
);

export default {
  data() {
    return {
      https: false,
      form: {
        email: "",
        password: "",
      },

      curUser: {
        user: null,
        expired: false,
      },

      isLoading: false,
      httpUrl:"",
      httpsUrl:""
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.https = window.location.protocol == "https:";
  
    this.httpUrl=window.location.href.replace('https:','http:');
    this.httpsUrl=window.location.href.replace('http:','https:');
  
  },
  methods: {
    dateToInt: (date) => {
      return date.getTime();
    },
    handleExpired(e) {
      if(this.curUser.expired)e.preventDefault();
    },
    async expired() {
      const uid = this.curUser.user.uid;
      console.log(uid);
      let usersRef = ref.child("users")
      let userRef=usersRef.child(uid);
      let dateRef=userRef.child("firstUse");

      let paidRef=userRef.child("paid");
      
      let firstDate = await dateRef.get();
      firstDate = await firstDate.exportVal();
      let paid=await paidRef.get();
      
      paid=await paid.exportVal();
      paid=true; //remove later!
      this.curUser.paid=paid;
      let curDate=this.dateToInt(new Date());
      if(paid)return false;
      
      if(firstDate==null){
        await dateRef.set(curDate);
        
        return false;
      }else return curDate - firstDate > 7200000; //change to 7200000 later
      
    },
    validateUser() {
      return this.curUser.paid;
    },
    async signup() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((userCredential) => {
          this.curUser.user = userCredential.user;
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    async signin() {
      this.isLoading = true;
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((u) => {
          this.curUser.user = u.user;
        })
        .then(()=>{
          return this.expired();
        })
        .then((expiredBool)=>{
          if(expiredBool) {
            return this.curUser.expired = true;

          }
          if(this.validateUser())this.$emit('mount10000');
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    async signout() {
      this.isLoading = true;
      if (this.curUser.user != null) {
        await firebase
          .auth()
          .signOut()
          .then(() => {
            this.curUser.user = null;
            this.$emit('loggedout');
          })
          .catch((e) => alert(e.message))
          .then(() => {
            this.$emit("unmount10000");
          });
      } else alert("Not signed in");
      this.isLoading = false;
    },

    async onAuthSubmit(e) {
      e.preventDefault();

      const u = await firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((u) => {
          this.curUser.user = u.user;

          return 2;
        })
        .catch((e) => {
          if (e.code == "auth/email-already-in-use") return 1;
          return 0;
        });
      if (u == 1) await this.signin();
      if(u!=0)this.$emit('loggedin');
      this.isLoading = false;
    },
    async submit() {
      const stripe = await stripePromise;
      // You will be redirected to Stripe's secure checkout page
      if(window.location.protocol!="https:"){
        window.location.href=this.httpsUrl;
        
      }
      await stripe.redirectToCheckout({
        lineItems: [
          {
            price: "price_1IYzm6KOVzXdHVhJP5pEWgjr", // Replace with the ID of your price
            quantity: 1,
          },
        ],
        mode: "payment",
        successUrl: "https://otakuroll.net/",
        cancelUrl: "https://otakuroll.net/",
      });
    },
  },
};
</script>