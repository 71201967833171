export function dequeueify(queue) {
    var arr = new Array(queue.length);
    while (queue.length)
      arr[arr.length - queue.length] = queue.dequeue()["ind"];
    return arr;
  }
export function queueify(queue, index, obj, size) {
    if (queue.length < size) {
      queue.queue(obj);
    } else if (queue.peek()["val"] < obj["val"]) {
      queue.dequeue();
      queue.queue(obj);
    }
  }

  export function dotProd(embedded, vec) {
    var dot = 0.0;
    for (var i = 0; i < embedded.length; i++) dot += embedded[i] * vec[i];
    return dot;
  }