<template>
  <div class="hello" style="display: flex; flex-direction: column">
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      >
      </loading>
    </div>
    <h1>{{ msg }}</h1>
    
    <div>
      <br>
      <p v-if="!signedin"><span style="font-weight:bold">Sign in</span> to access all 10,000 animes, import directly from MAL, and use DeepNaniNet!</p>   
      <p>Latest DeepNaniNet performs <b>>50x</b> better than random guessing in predicting what you'll watch next!</p>
      <p>List some shows you enjoyed in the past - the more the better our predictions.</p>
         
      
      <MAL @xmlRes="gotXml" :paid="paid" :signedin="signedin"/>
 x
      <!-- <small>Redirect here: <a v-bind:href="httpUrl">if DeepAniNet isn't working</a> or <a v-bind:href="httpsUrl">if Donate isn't working</a>.</small> -->
      <hr style="width:400px"/>
    </div>
    <ul style="display: flex; flex-direction: column; self-align: center">
      <li
        style="display: flex; justify-content:center; flex-direction:row;width: 40%; margin: auto"
        v-for="(item,index) in selectedNames"
        :key="item"
      >

        <div
          style="
            background-color: BlanchedAlmond;
            border-style: solid;
            width:100%;
            margin: 0.1%;
            font-size: 12pt;
          "
        >
          {{ item }}
        </div>

        <button @click="deleteFromList(index)">Delete</button>
      </li>
    </ul>
    
    <ul style="display: flex; flex-direction: column">
      <li
        style="flex-direction: column"
        v-for="(rec, index) in recommendations"
        :key="rec"
      >
        <div>Pick #{{ index+1 }}: {{ rec }}</div>
        <a v-bind:href="`https://myanimelist.net/anime/${best[index]}`"
          >Learn more</a
        >
        
      </li>
    </ul>
    <p v-if="similarUsers.length">
      Here're some users who may have a similar taste as you!
    </p>
    <ul style="display: flex; flex-direction: column">
      <li style="flex-direction: row" v-for="user in similarUsers" :key="user">
        <div>{{ malUsers[user][1] }}</div>
        <a v-bind:href="`https://myanimelist.net/profile/${malUsers[user][1]}`"
          >Profile</a
        >
      </li>
    </ul>

    <div style="margin-left: 30%; margin-right: 30%">
      
      <b-modal v-model="showPaidFeature">
        Log in to access this feature!
      </b-modal>
      <autocomplete :search="search" @submit="onSubmit"></autocomplete>
      
      <input id="numShowsInput" type="number" max=100 min=1 v-bind:placeholder="checkPaidFeature ? 3:50" v-on:click="checkPaidFeature"/>
      <button @click="() => recommend(this)">
        Give me a recommendation!
        
      </button>
      
      <input id="maxLimInput" type="number" max=10000 min=100 v-bind:placeholder="checkPaidFeature ? 200:1000" v-on:click="checkPaidFeature"/>
      <button @click=clear >Clear</button>
      <b-button id="inputInfo" v-b-popover.hover.top:="'1) How many recommendations you want? 2) Tell DeepNaniNet to limit search to top X animes only.'" size="lg" variant="primary" class="mb-2">
          <b-icon icon="question-circle-fill" aria-label="Help"></b-icon>
      </b-button>
      

      
      
      
      <br>
      
      
      
    </div>
    
    
  </div>
</template>

<script>
// import mdiInformation from 'vue-material-design-icons/Information.vue';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Autocomplete from "@trevoreyre/autocomplete-vue";

import MAL from './MAL.vue';
import PriorityQueue from "js-priority-queue";
import firebase from "firebase";
import jsonUser from "../assets/smallGraph/user_factors.json";
import jsonItem from "../assets/smallGraph/item_factors.json";
import jsonMALUsers from "../assets/smallGraph/temp.json";

import jsonUser10000 from "../assets/largeGraph/10000_full_train_user_factors.json";
import jsonItem10000 from "../assets/largeGraph/10000_full_train_item_factors.json";
import jsonMALUsers10000 from "../assets/largeGraph/10000_temp.json";

import { ApiUtil } from "@/utils/api-utils.js";
import {dotProd,queueify,dequeueify} from "@/utils/helpers.js";
var app = firebase.initializeApp({
  apiKey: "AIzaSyDyJ8b1vndB7N1M_TDZ3YSGmYaWlKNehxM",
  authDomain: "otakuroll-c674d.firebaseapp.com",
  databaseURL: "https://otakuroll-c674d-default-rtdb.firebaseio.com",
  projectId: "otakuroll-c674d",
  storageBucket: "otakuroll-c674d.appspot.com",
  messagingSenderId: "683467049660",
  appId: "1:683467049660:web:bfd88b1efca162f8179eb9",
});
var db = firebase.database(app);
var ref = db.ref();
export default {
  name: "HelloWorld",
  props: {
    msg: String,
    mode: Number,
    paid: Boolean,
    signedin: Boolean
  },
  data() {
    return {
      selected: [],
      selectedNames: [],
      selectedIndices: [],
      userFactors: jsonUser,
      itemFactors: jsonItem,
      malUsers: jsonMALUsers,
      recommendations: [],
      best: [],
      similarUsers: [],
      expired: false,
      httpUrl: "",
      httpsUrl: "",

      isLoading: false,
      // mal:"",
      showPaidFeature:false
    };
  },
  components: {
    Autocomplete,
    Loading,
    MAL,
  },
  watch: {
    
    paid: function(newVal) {
      
        this.userFactors=newVal?jsonUser10000:jsonUser;
        this.itemFactors=newVal?jsonItem10000:jsonItem;
        this.malUsers=newVal?jsonMALUsers10000:jsonMALUsers;
        if(newVal){
          document.getElementById("numShowsInput").value=50;
          document.getElementById("maxLimInput").value=10000;
        }
    }
  },
  mounted() {
    this.httpUrl=window.location.href.replace('https:','http:');
    this.httpsUrl=window.location.href.replace('http:','https:');
    
  },

  methods: {
    async dbLookup(v) {
      let res_child = ref.child((this.paid? "dic/" : "dic_small/") + v + "/name");
      let index_child = ref.child((this.paid? "dic/" : "dic_small/") + v + "/index");
      const res_prom = await res_child.get();
      const index_prom = await index_child.get();
      const ret = res_prom.exportVal();
      const ind = index_prom.exportVal();
      let lookup=Object();
      lookup.ret=ret;
      lookup.ind=ind;
      return lookup;
    },
    async gotXml(res) {
      for(let v of res){
        let lookedup=await this.dbLookup(v);
        if(this.selectedIndices.includes(v)) continue;
        this.selectedIndices.push(v);
        this.selectedNames.push(lookedup.ret);
        this.selected.push(lookedup.ind);
      }

    },
    remIndex: (lis,index) => {
      let slice1=lis.slice(0,index);
      let slice2=lis.slice(index+1,lis.length);

      return slice1.concat(slice2);
    },
    deleteFromList(index) {
      this.$emit('rezoom',this.selectedIndices.slice(index,index+1));

      
      this.selectedNames=this.remIndex(this.selectedNames,index);
      
      this.selected=this.remIndex(this.selected,index);
      this.selectedIndices=this.remIndex(this.selectedIndices,index);
      
    },
    checkPaidFeature() {
      
      if(!this.paid){
        document.getElementById("numShowsInput").value = 3;
        document.getElementById("maxLimInput").value = 200;
        this.showPaidFeature=true;

        return false; 
      }

      return true;
    },
    async malCall() {
      if(!this.checkPaidFeature())return;
      this.isLoading=true;
      let ids=await ApiUtil.getMalUser(this.mal,this.paid);
      ids=ids["ids"];
      this.selected=[];
      let titles=[];
      let validIds=[];
      let indices=[];
      for(let id of ids) {
        
        let path=`${'dic'}/${id}`;
        var malRef=ref.child(path);
        let indexRef=malRef.child("index");
        let nameRef=malRef.child("name");
        const indexProm = await indexRef.get();
        const nameProm = await nameRef.get();
        const indexVal = await indexProm.exportVal();
        const nameVal = await nameProm.exportVal();
        if(nameVal==null)continue;
        titles.push(nameVal);
        indices.push(indexVal);
        validIds.push(id);
      }

      this.selectedNames=titles;
      this.selectedIndices=indices;
      this.selected=validIds;

      this.isLoading=false;
    },
    clear() {
      this.$emit('rezoom',this.selectedIndices);
      this.selected=[];
      this.selectedNames=[];
      this.selectedIndices=[];
      this.recommendations=[];
      this.best=[];
      this.similarUsers=[];
      
    },
    numShows: () => {
      document.getElementById("numShowsInput").defaultValue = 3;
      return parseInt(document.getElementById("numShowsInput").value);
    },
    
    async onSubmit(input) {
      var ind = parseInt(input.split(":")[0]);
      this.selected.push(ind);
      this.selectedIndices.push(this.itemFactors[ind][0]);
      this.selectedNames.push(input.split(":")[1]);
      this.$emit("zoom", this.itemFactors[ind][0]);
    },
    
    retrieve() {
      var res = new Array(this.itemFactors[0].length - 1).fill(0);
      for (const ind of this.selected) {
        const cur = this.itemFactors[ind].slice(1);
        for (var i = 0; i < cur.length; i++) res[i] += cur[i];
      }
      for (i = 0; i < res.length; i++) res[i] /= this.selected.length;
      return res;
    },
    topK(embedded, items = true) {
      var size = items ? this.numShows() : 5;
      var queue = new PriorityQueue({
        comparator: function (a, b) {
          return a["val"] - b["val"];
        },
      });
      var factors = items ? this.itemFactors : this.userFactors;
      const len = factors.length;
      for (var j = 0; j < len; j++) {
        if (this.selected.includes(j)) continue;
        let vec = factors[j].slice(1);
        let index = factors[j][0];
        var dot = dotProd(embedded, vec);
        const obj = { ind: index, val: dot };
        queueify(queue, index, obj, size);
      }

      return dequeueify(queue);
    },
    avgAffinity(names, i) {
      var avg = 0.0;
      const itemVec = this.itemFactors[i].slice(1);
      for (var j = 0; j < names.length; j++) {
        const userVec = this.userFactors[names[j]].slice(1);
        var aff = dotProd(userVec, itemVec);
        avg += aff;
      }
      avg /= names.length;
      return avg;
    },
    judge(names) {
      var queue = new PriorityQueue({
        comparator: function (a, b) {
          return a["val"] - b["val"];
        },
      });
      for (var i = 0; i < this.itemFactors.length; i++) {
        const index = this.itemFactors[i][0];
        if (this.selectedIndices.includes(index)) continue;
        var score = this.avgAffinity(names, i);
        const obj = { ind: index, val: score };
        queueify(queue, index, obj, this.numShows());
      }
      return dequeueify(queue);
    },
    async identify(best) {
      var names = [];
      var indices = [];
      for (var nameId of best) {
        var nameRef = ref.child((this.paid? "dic/" : "dic_small/") + nameId + "/name");
        const prom = await nameRef.get();
        const ret = prom.exportVal();
        names.push(ret);
        indices.push(nameId);
      }
      this.best = indices;
      return names;
    },
    async call() {
      
      try {
        let numShows=this.numShows();
        let maxLim=document.getElementById("maxLimInput").value;
        const data = await ApiUtil.retrieveRecs(this.selectedIndices,numShows,this.paid,maxLim);
        this.recommendations = data["titles"];
        for(var i=0;i<data["ids"].length;i++) this.best.push(parseInt(data["ids"][i]));
      } catch (err) {
        if(this.selectedIndices.length==0) alert("You need to list some shows first!");
        else {
        alert(
          err
        );}
      }
      
    },
    async recommend() {
      if(this.mode == 2) {this.best = []; await this.call();}
      else {
        this.recommendations = [];
        this.similarUsers = [];
        this.best = [];
        const embedded = this.retrieve();
        const topKNames = this.topK(embedded, this.mode == 0);
        var recommendations = topKNames;
        if (this.mode) recommendations = this.judge(recommendations);
        this.recommendations = await this.identify(recommendations);
        if (this.mode) this.similarUsers = topKNames;
      }
      this.$emit('recommended',this.best);
      
    },

    async search(input) {
      if (input.length < 1) {
        
        return [];
      }
      var a = input.split("");
      a[0] = a[0].toUpperCase();
      var s = this.paid ? "trie" : "trie_small";
      for (var st of a.slice(0, -1)) s += "/" + st + "/cont";
      s += "/" + a[a.length - 1] + "/auto";
      var childRef = ref.child(s);
      const prom = await childRef.get();
      const ret = prom.exportVal();
      if(ret==null)return [];
      var results = [];
      for (const v of Object.values(ret)) {
        let lookedup=await this.dbLookup(v);
        results.push(lookedup.ind + ": " + lookedup.ret);
      }
      return results;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #fa4747;
  text-decoration: underline;

}
#inputInfo {
  background-color:transparent;
  
  position:relative;
  border:none;
  width:5px;
  height:5px;
  margin-top:-9%;
  top:18px;
}
</style>
